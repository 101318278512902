import React from 'react'

import { LatestEntriesStoryblok } from 'common/types'
import { LatestBlogPosts, LatestEvents } from 'modules/LatestEntries'

type Props = {
  block: LatestEntriesStoryblok
}

export const LatestEntries = ({
  block,
  ...props
}: Props): JSX.Element | null => {
  switch (block.content_type) {
    case 'blog-post':
      return <LatestBlogPosts block={block} {...props} />
    case 'open-evening':
      return <LatestEvents block={block} {...props} />
    default:
      return null
  }
}
